/* eslint-disable @next/next/no-img-element */
// import classNames from 'classnames';

const SolutionsOpeningGraphic = () => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-none items-center justify-center rounded-xl border border-[#424545] py-12 mobile:py-0">
        <img
          src="/promotional/b2b/landing/aapl.svg"
          width={222}
          height={262}
          alt="Apple Token"
          className="mobile:hidden"
        />

        <img
          src="/promotional/b2b/landing/aapl-tablet.svg"
          width={648}
          height={425}
          alt="Apple Token"
          className="hidden mobile:block container:hidden"
        />

        <img
          src="/promotional/b2b/landing/aapl-desktop.svg"
          width={798}
          height={373}
          alt="Apple Token"
          className="hidden container:block"
        />
      </div>

      <div className="flex flex-col gap-[12px]">
        <p className="whitespace-pre-line text-center">
          Currently we offer equity-backed assets - including the entire S&P 500 - and have the
          capability to deliver additional advanced digital-assets in the future. Our tokens allow
          exchanges, DeFi protocols, wallets, and other Web3 applications to compliantly integrate
          real world assets into their products and services with minimal effort. Integration
          options are detailed at the end of this page.
        </p>
      </div>
    </div>
  );
};

export default SolutionsOpeningGraphic;
