/* eslint-disable @next/next/no-img-element */
import trackUserEvent from '@/util/eventTracking';
import { Button } from 'ui';

const SolutionsHero = () => {
  return (
    <header className="flex flex-col items-center justify-center gap-6 py-12 px-4 mobile:px-12 mobile:py-24">
      <div className="flex max-w-[980px] flex-col items-center gap-4 text-center">
        <div className="flex flex-col gap-4 mobile:gap-0">
          <h1 className="font-medium text-header-d-1 mobile:leading-[58px] mobile:[font-size:_48px_!important]">
            On-chain equities.
          </h1>
          <h1 className="font-medium text-header-d-1 mobile:leading-[58px] mobile:[font-size:_48px_!important]">
            Backed and fully compliant.
          </h1>
        </div>

        <p>Get access to hundreds of fully-compliant, liquid, tokenized equities.</p>
      </div>

      <a href="mailto:sales@structure.fi">
        <Button
          onClick={() => trackUserEvent('Clicked Hero B2B CTA')}
          className="font-normal"
          variant="primary"
        >
          Talk with us today
        </Button>
      </a>
    </header>
  );
};

export default SolutionsHero;
