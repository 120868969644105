/* eslint-disable @next/next/no-img-element */

import classNames from 'classnames';
import { Button } from 'ui';

import trackUserEvent from '@/util/eventTracking';
import SolutionsOpeningGraphic from './SolutionsOpeningGraphic';

interface SolutionsIntroRowProps {
  title: string;
  description: string;
  image: string;
  imageW: number;
  imageH: number;
  direction: 'left' | 'right';
}

const SolutionsTrustRow = ({
  title,
  description,
  direction,
  image,
  imageW,
  imageH,
}: SolutionsIntroRowProps) => {
  return (
    <div
      className={classNames([
        'flex flex-col gap-6 footer:items-center footer:flex-row footer:gap-14',
        { 'footer:flex-row-reverse': direction === 'right' },
      ])}
    >
      <div className="flex flex-none items-center justify-center rounded-xl border border-[#424545] py-12 footer:h-50 footer:min-w-[380px] footer:py-0">
        <img src={image} width={imageW} height={imageH} alt={title} />
      </div>

      <div className="flex flex-col gap-[12px]">
        <h3 className="text-[16px] font-medium leading-[26px]">{title}</h3>
        <p
          className="whitespace-pre-line"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
};

const SolutionsTrust = () => {
  return (
    <section className="flex flex-col items-center bg-black text-white">
      <div className="flex w-full max-w-[840px] flex-col px-4 py-12 mobile:max-w-[960px] mobile:px-12 tablet:px-24">
        <h2 className="mb-10 text-center font-medium leading-[40px] text-header-2">
          500+ tokenized stocks and ETFs ready for trading
        </h2>

        <div className="flex flex-col gap-8 mobile:gap-10">
          <div className="flex flex-col gap-10">
            <SolutionsOpeningGraphic />

            <SolutionsTrustRow
              title="1:1 Reserve-backed assets"
              description="Our tokenized assets are backed 1:1, with the underlying shares held securely by third-party, regulated financial institutions."
              direction="left"
              image="/promotional/b2b/landing/1_1.svg"
              imageW={138}
              imageH={150}
            />

            <SolutionsTrustRow
              title="Unlock the power of self-custody"
              description="Users of our tokens have total control, including the ability to withdraw their tokens to a private wallet at any time. All tokens are ERC20, and issued on Ethereum Mainnet (more chains coming soon)."
              direction="right"
              image="/promotional/b2b/landing/access.svg"
              imageW={139}
              imageH={180}
            />
          </div>

          <div className="flex flex-col items-center justify-center gap-6 rounded-xl border border-[#424545] py-8 px-6 mobile:mb-2 mobile:py-10">
            <h3 className="max-w-[528px] text-center font-medium text-header-4 tablet:max-w-[480px]">
              Have an asset your customers keep asking for? Drop us a line and we&apos;ll see if we
              can help.
            </h3>

            <a href="mailto:sales@structure.fi">
              <Button
                onClick={() => trackUserEvent('Clicked Closing B2B CTA')}
                className="border border-white bg-transparent font-normal hover:border-[#8A8A8A] hover:bg-transparent"
                variant="primary"
              >
                Let&apos;s talk assets
              </Button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SolutionsTrust;
