import { ComponentPropsWithoutRef, ComponentPropsWithRef, VFC } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Link from 'next/link';
import cn from 'classnames';

import trackUserEvent from '@/util/eventTracking';

import { LinkedInButton, TwitterButton, TelegramButton, DiscordButton, MailButton } from 'ui';

const SocialMedia = [
  {
    name: 'LinkedIn',
    href: {
      'en-US': 'https://www.linkedin.com/company/structurefi/',
      'es-SV': 'https://www.linkedin.com/company/structurefi/',
    },
    Icon: LinkedInButton,
  },
  {
    name: 'Twitter',
    href: {
      'en-US': 'https://twitter.com/structure_fi',
      'es-SV': 'https://twitter.com/StructureFi_Esp',
    },
    Icon: TwitterButton,
  },
  {
    name: 'Telegram',
    href: {
      'en-US': 'https://t.me/Structure_Official',
      'es-SV': 'https://t.me/Structure_Official',
    },
    Icon: TelegramButton,
  },
  {
    name: 'Discord',
    href: {
      'en-US': 'https://discord.gg/5KGUYEsxBv',
      'es-SV': 'https://discord.gg/5KGUYEsxBv',
    },
    Icon: DiscordButton,
  },
  {
    name: 'Email',
    href: {
      'en-US': 'mailto:support@structure.fi',
      'es-SV': 'mailto:support@structure.fi',
    },
    Icon: MailButton,
  },
];

const FooterLink: VFC<
  {
    name: string;
    href: string;
  } & ComponentPropsWithRef<'li'>
> = ({ name, href, className, ref }) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
    <li
      ref={ref}
      className={cn(className, 'whitespace-nowrap font-medium')}
      key={name}
      onClick={() =>
        trackUserEvent(`[Footer] Link clicked`, {
          name,
          href,
        })
      }
    >
      <Link className="font-medium text-subtitle-1 tracking-tight" href={href}>
        {name}
      </Link>
    </li>
  );
};

const SolutionsFooter: VFC<ComponentPropsWithoutRef<'div'>> = ({
  className,
}: {
  className?: string;
}) => {
  const { t } = useTranslation(['common', 'footer']);
  const { locale } = useRouter();

  const renderLinkTree = () => {
    const links = [
      [t('pages.tc'), '/legal/terms'],
      [t('pages.privacy'), '/legal/privacy'],
      [t('pages.about'), '/about'],
      [t('pages.whitepaper'), '/StructureWhitepaper.pdf'],
      // [t('pages.careers'), '/careers'],
      [t('pages.blog'), '/blog'],
      // [t('pages.contact'), 'mailto:support@structure.fi'],
    ];

    return (
      <div className="flex  max-w-desktop flex-col tablet:justify-between tablet:gap-10 footer:flex-row footer:items-start desktop:gap-20">
        {/* Link "Tree" Mobile */}
        <ul className="flex grid-flow-col flex-wrap justify-between gap-8 hero-2:justify-start tablet:hidden">
          {links.map(([name, href]) => (
            <FooterLink key={name} name={name} href={href} className="" />
          ))}
        </ul>

        {/* Link "Tree" Tablet/Desktop */}
        <ul className="hidden w-full min-w-0 grid-cols-1 justify-between tablet:flex tablet:max-w-[700px] desktop:gap-5 desktop:text-center">
          {links.map(([name, href]) => (
            <FooterLink key={name} name={name} href={href} />
          ))}
        </ul>
      </div>
    );
  };

  return (
    <footer
      className={cn(
        'flex w-full flex-col z-0 items-center border-t border-gray-6 px-4 pt-8 pb-10 gap-8 mobile:px-6 mobile:pb-19 tablet:pt-10',
        className,
      )}
    >
      <div className="flex w-full max-w-desktop flex-col-reverse gap-6 mobile:px-5 desktop:flex-row desktop:items-center desktop:justify-between">
        {renderLinkTree()}

        <div className="flex justify-between">
          {/* Social Media Links */}
          <ul className="flex shrink-0 flex-wrap gap-4 mobile:mt-6 tablet:mt-0">
            {SocialMedia.map(({ name, href, Icon }) => (
              <li key={name}>
                <a href={href[locale] || href['en-US']}>
                  <Icon
                    onClick={() =>
                      trackUserEvent(`[Footer] Social Icon Clicked`, {
                        name,
                        href,
                      })
                    }
                    className="h-8 w-8"
                  />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="flex w-full max-w-desktop flex-col gap-3 mobile:px-5">
        <p className="text-caption">
          © Copyright 2023 Structure Financial, Inc. All Rights Reserved.
        </p>

        <p className="text-caption">
          Not all assets will be available to trade without restriction at any time. Assets
          currently available for trading will be displayed in the application. For additional
          details, please review our{' '}
          <Link href="/legal/terms">
            <a className="text-blue-3">Terms and Conditions</a>
          </Link>{' '}
          and our{' '}
          <Link href="/legal/privacy">
            <a className="text-blue-3">Privacy Policy</a>
          </Link>
          .
        </p>

        <p className="text-caption">
          Structure has partnered with custody providers who utilize secure multi-party computation
          and other security measures to protect your assets and information. No system is ever
          completely secure and Structure is not responsible for any losses incurred due to issues
          at our custody provider.
        </p>

        <p className="text-caption">
          This is not an offer, solicitation of an offer or advice to buy or sell securities, or
          open a brokerage account in any jurisdiction where Structure Financial, Inc. is not
          registered.
        </p>

        <p className="text-caption">
          Structure Financial, Inc does not recommend any assets or securities. All investments
          involve risk and the past performance of an asset, security or financial product does not
          guarantee future results or returns. Keep in mind that while diversification may help
          spread risk it does not assure a profit, or protect against loss, in a down market. There
          is always the potential of losing money when you invest in securities or other financial
          products. Investors should consider their investment objectives and risks carefully before
          investing.All assets, and investments are offered to self-directed customers by Structure
          Financial, Inc. Structure Financial Inc. is not a member of FINRA, FDIC, SIPC or
          registered with the United States Securities and Exchange Commission.
        </p>

        <p className="text-caption">
          Structure Financial, Inc&apos;s services and STXR are not available in the United States
          and other prohibited jurisdictions.
        </p>
      </div>
    </footer>
  );
};

export default SolutionsFooter;
