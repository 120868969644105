import classNames from 'classnames';
import Link from 'next/link';

const SolutionsInvestors = () => {
  return (
    <div className="flex justify-center">
      <div className="flex max-w-[1300px] flex-col items-center justify-between gap-10 px-4 py-12 mobile:gap-13 mobile:px-12 desktop:py-20">
        <div className="flex flex-col justify-center gap-[28px] text-center desktop:max-w-[495px]">
          <h3 className="font-medium text-header-2">Backed by the best</h3>
          <p className="text-body-1">
            We&apos;re backed by top investors who believe in our mission to democratize access to
            traditional financial assets.
          </p>
        </div>

        <div className="flex w-full flex-col gap-8">
          <div className="grid grid-cols-2 place-items-center gap-10 mobile:grid-cols-4 mobile:justify-between">
            {[
              ['Polychain', 'https://polychain.capital/', '65'],
              ['Morningstar', 'https://morningstar.ventures/', '105'],
              ['Bixin', 'https://bixinvc.com/', '105'],
              ['Ascensive', 'https://ascensiveassets.com/', '60'],
            ].map(([name, url, w]) => (
              <div
                key={name}
                className="w-[40%] max-w-25 tablet:w-[60%] tablet:max-w-30"
                style={{
                  width: `${w}%`,
                }}
              >
                <Link target="_blank" href={url}>
                  <a>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      src={`/about/${name.toLowerCase().trim().replace(/\s/g, '')}.png`}
                      alt={name}
                      className={classNames({ 'desktop:ml-3': name === 'New Form' })}
                    />
                  </a>
                </Link>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-2 place-items-center gap-10 mobile:grid-cols-3 mobile:justify-between">
            {[
              ['Finality', 'https://twitter.com/finalitycap', '105'],
              ['Blockchain Coinvestors', 'https://www.blockchaincoinvestors.com/', '75'],
              ['New Form', 'https://www.newformcap.com/', '80'],
            ].map(([name, url, w]) => (
              <div
                key={name}
                className="w-[40%] max-w-25 tablet:w-[60%] tablet:max-w-30"
                style={{
                  width: `${w}%`,
                }}
              >
                <Link target="_blank" href={url}>
                  <a target="_blank" rel="noreferrer">
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      src={`/about/${name.toLowerCase().trim().replace(/\s/g, '')}.png`}
                      alt={name}
                      className={classNames({ 'desktop:ml-3': name === 'New Form' })}
                    />
                  </a>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionsInvestors;
