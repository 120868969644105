/* eslint-disable @next/next/no-img-element */
import { Button } from 'ui';
import trackUserEvent from '@/util/eventTracking';

interface SolutionsLiquidityRowProps {
  title: string;
  description: string;
  image: string;
  imageW: number;
  imageH: number;
}

const SolutionsLiquidityRow = ({
  title,
  description,
  image,
  imageW,
  imageH,
}: SolutionsLiquidityRowProps) => {
  return (
    <div className="flex flex-1 flex-col gap-6 mobile:items-center">
      <div className="flex w-full flex-none items-center justify-center rounded-xl py-8 mobile:max-w-[400px] desktop:h-42 desktop:max-w-[300px]">
        <img src={image} width={imageW} height={imageH} alt={title} />
      </div>

      <div className="flex flex-col gap-[12px] text-center">
        <h3 className="text-[16px] font-medium leading-[26px]">{title}</h3>
        <p className="whitespace-pre-line text-gray-1 mobile:max-w-[760px]">{description}</p>
      </div>
    </div>
  );
};

const SolutionsLiquidity = () => {
  return (
    <section className="flex flex-col items-center bg-[#F5F0EB]">
      <div className="flex w-full max-w-[1180px] flex-col py-12 px-4 mobile:py-16 mobile:px-12 tablet:px-24 footer:px-12 desktop:px-0">
        <h2 className="mb-10 text-center font-medium leading-[40px] text-header-2">
          API-driven liquidity you can depend on
        </h2>

        <div className="flex flex-col gap-10 desktop:flex-row desktop:px-2">
          <SolutionsLiquidityRow
            title="Reliable liquidity"
            description="Liquidity for the underlying assets is sourced from top-tier, US-registered broker-dealers, ensuring high liquidity for Structure's tokenized assets."
            image="/promotional/b2b/landing/liquidity/1.svg"
            imageW={237}
            imageH={163}
          />

          <SolutionsLiquidityRow
            title="Real-time creation & redemption"
            description="Structure maintains 1:1 backing of assets in real-time. As tokens are minted or burned, the underlying assets are bought and sold."
            image="/promotional/b2b/landing/liquidity/2.svg"
            imageW={202}
            imageH={150}
          />

          <SolutionsLiquidityRow
            title="US Registered Broker-Dealers"
            description="Execution and clearing for the underlying assets is done through registered broker-dealers."
            image="/promotional/b2b/landing/liquidity/3.svg"
            imageW={214}
            imageH={150}
          />
        </div>

        <div className="my-8 h-[1px] w-full bg-[#DBD7D3] mobile:my-10" />

        <div className="flex flex-col items-center gap-6 text-center mobile:flex-row mobile:items-center mobile:justify-center mobile:gap-10 mobile:text-left container:flex-col container:gap-6">
          <h3 className="flex-auto font-medium text-header-4 mobile:max-w-[368px] container:max-w-[unset]">
            Have questions about our liquidity or secondary market solutions? Let&apos;s talk.
          </h3>
          <a className="flex-none" href="mailto:sales@structure.fi">
            <Button
              onClick={() => trackUserEvent('Clicked solutions specialist CTA')}
              className="font-normal"
              variant="primary"
            >
              Talk with a solutions specialist
            </Button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default SolutionsLiquidity;
