/* eslint-disable @next/next/no-img-element */

import classNames from 'classnames';
import { Button } from 'ui';

import trackUserEvent from '@/util/eventTracking';

interface SolutionsIntroRowProps {
  title: string;
  description: string;
  image: string;
  className?: string;
  imageW: number;
  imageH: number;
  direction: 'left' | 'right';
}

const SolutionsComplianceRow = ({
  title,
  description,
  direction,
  className,
  image,
  imageW,
  imageH,
}: SolutionsIntroRowProps) => {
  return (
    <div
      className={classNames([
        'flex flex-col gap-6 footer:items-center footer:flex-row footer:gap-14',
        { 'footer:flex-row-reverse': direction === 'right' },
      ])}
    >
      <div
        className={classNames(
          'flex flex-none items-center justify-center rounded-xl tablet:py-12 footer:h-50 footer:min-w-[380px] footer:py-0',
          className,
        )}
      >
        <img src={image} width={imageW} height={imageH} alt={title} />
      </div>

      <div className="flex flex-col gap-[12px]">
        <h3 className="text-[16px] font-medium leading-[26px]">{title}</h3>
        <p
          className="whitespace-pre-line"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
};

const SolutionsCompliance = () => {
  return (
    <section className="flex flex-col items-center">
      <div className="flex w-full max-w-[840px] flex-col px-4 py-12 mobile:max-w-[960px] mobile:px-12 tablet:px-24 footer:px-12">
        <h2 className="mb-10 text-center font-medium leading-[40px] text-header-2">
          Compliant tokens and secure custody
        </h2>

        <div className="flex flex-col gap-8 mobile:gap-10">
          <div className="flex flex-col gap-10">
            <SolutionsComplianceRow
              title="Segregated funds"
              description="Underlying assets backing Structure's issued tokens are segregated and held by separate legal entities."
              direction="left"
              className="border border-gray-6 py-7"
              image="/promotional/b2b/landing/pltr.svg"
              imageW={268}
              imageH={180}
            />

            <SolutionsComplianceRow
              title="Institutional-grade bank custody"
              description="All assets backing Structure's tokens are held at a top-tier custodial bank."
              direction="right"
              className="border border-gray-6 py-7"
              image="/promotional/b2b/landing/compliance/2.svg"
              imageW={238}
              imageH={176}
            />

            <SolutionsComplianceRow
              title="KYC and trade data management"
              description="We require KYC for all customers, monitor primary-market transactions involving Structure assets, conduct ongoing OFAC screening, and enforce jurisdiction limits on a per-asset basis where required."
              direction="left"
              className="border border-gray-6 py-7"
              image="/promotional/b2b/landing/compliance/3.svg"
              imageW={206}
              imageH={180}
            />
          </div>

          <div className="flex flex-col items-center justify-center gap-6 rounded-xl border border-gray-6 py-8 px-6 mobile:mb-2 mobile:flex-row mobile:py-10 tablet:gap-16">
            <h3 className="max-w-[528px] text-center font-medium text-header-4 mobile:text-left tablet:max-w-[480px]">
              Questions about compliance? We&apos;ve got someone you can talk to.
            </h3>

            <a className="flex-none" href="mailto:sales@structure.fi">
              <Button
                onClick={() => trackUserEvent('Clicked Closing B2B CTA')}
                className="border"
                variant="primary"
              >
                Let&apos;s talk compliance
              </Button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SolutionsCompliance;
