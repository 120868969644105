/* eslint-disable @next/next/no-img-element */
import { useState } from 'react';

import { Button } from 'ui';
import classNames from 'classnames';

import { Listbox, Transition } from '@headlessui/react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter/dist/cjs/index';
import { hopscotch } from 'react-syntax-highlighter/dist/cjs/styles/hljs';

import trackUserEvent from '@/util/eventTracking';

const languages = [
  {
    name: 'REST',
    color: '#424545',
    ast: 'JSON',
    snippet: `{
  "side": "BUY",
  "onBehalfOf": {
      "type": "CUSTOMER",
      "id": "3e053ecc-b7b0-11ed-afa1-0242ac120002"
  },
  "asset": {
      "buy": "AAPL",
      "sell": "BTC"
  },
  "requestedSize": "0.35"
}`,
  },
  {
    name: 'TypeScript',
    color: '#D5F285',
    ast: 'typescript',
    snippet: `const options = {
  method: 'PUT',
  body: JSON.stringify({
    side: 'BUY',
    onBehalfOf: { type: 'CUSTOMER', id: '3e053ecc-b7b0-11ed-afa1-0242ac120002' },
    asset: { buy: 'AAPL', sell: 'BTC' },
    requestedSize: '0.35'
  })
};

await fetch('https://api.structure.fi/v1/trades', { method: 'PUT' });`,
  },
  {
    name: 'Java',
    color: '#D98E36',
    ast: 'java',
    snippet: `OkHttpClient client = new OkHttpClient();

MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{"side":"BUY","onBehalfOf":{"type":"CUSTOMER","id":"3e053ecc-b7b0-11ed-afa1-0242ac120002"},"asset":{"buy":"AAPL","sell":"BTC"},"requestedSize":"0.35"}");
Request request = new Request.Builder()
  .url("https://api.structure.fi/v1/trades")
  .put(body)
  .addHeader("accept", "application/json")
  .addHeader("content-type", "application/json")
  .build();

Response response = client.newCall(request).execute();`,
  },
  {
    name: 'Python',
    color: '#EDD977',
    ast: 'python',
    snippet: `import requests

url = "https://api.structure.fi/v1/trades"

payload = {
    "side": "BUY",
    "onBehalfOf": {
        "type": "CUSTOMER",
        "id": "3e053ecc-b7b0-11ed-afa1-0242ac120002"
    },
    "asset": {
        "buy": "AAPL",
        "sell": "BTC"
    },
    "requestedSize": "0.35"
}

headers = {
    "accept": "application/json",
    "content-type": "application/json"
}

response = requests.put(url, json=payload, headers=headers)`,
  },
  {
    name: 'Rust',
    color: '#D85426',
    ast: 'rust',
    snippet: `use reqwest::header::{ACCEPT, CONTENT_TYPE};
use serde::{Deserialize, Serialize};

#[derive(Serialize, Deserialize)]
struct OnBehalfOf {
    r#type: String,
    id: String,
}

#[derive(Serialize, Deserialize)]
struct Asset {
    buy: String,
    sell: String,
}

#[derive(Serialize, Deserialize)]
struct TradeRequest {
    side: String,
    onBehalfOf: OnBehalfOf,
    asset: Asset,
    requestedSize: String,
}

fn main() -> Result<(), Box<dyn std::error::Error>> {
    let url = "https://api.structure.fi/v1/trades";

    let payload = TradeRequest {
        side: "BUY".to_string(),
        onBehalfOf: OnBehalfOf {
            r#type: "CUSTOMER".to_string(),
            id: "3e053ecc-b7b0-11ed-afa1-0242ac120002".to_string(),
        },
        asset: Asset {
            buy: "AAPL".to_string(),
            sell: "BTC".to_string(),
        },
        requestedSize: "0.35".to_string(),
    };

    let client = reqwest::blocking::Client::new();
    let response = client
        .put(url)
        .json(&payload)
        .header(ACCEPT, "application/json")
        .header(CONTENT_TYPE, "application/json")
        .send()?;

    println!("{:#?}", response.text()?);

    Ok(())
}`,
  },
];

const SolutionsCodeEditor = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);

  return (
    <div className="flex w-full min-w-[0px] flex-col gap-4 rounded-xl border border-gray-2 p-4 container:flex-1">
      <div className="flex items-center justify-between gap-3 border-b border-gray-2 pb-4">
        <h4 className="text-[16px] font-medium">Buy order request</h4>

        <Listbox
          value={selectedLanguage}
          as="div"
          className="relative"
          onChange={setSelectedLanguage}
        >
          {({ open }) => (
            <>
              <Listbox.Button className="group relative flex w-fit items-center gap-2 rounded-lg border-transparent bg-transparent py-0 text-white transition-colors focus:border-transparent focus:ring-0">
                <img
                  src={`/promotional/b2b/landing/dropdown/${selectedLanguage.name}.svg`}
                  className="transition-all duration-75 group-hover:[filter:brightness(0)_saturate(100%)_invert(99%)_sepia(48%)_saturate(1130%)_hue-rotate(16deg)_brightness(93%)_contrast(105%)]"
                  alt=""
                />

                <span className="transition-colors group-hover:text-[#D5F285]">
                  {selectedLanguage.name}
                </span>

                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  className={`${
                    !open && 'rotate-180'
                  } stroke-white transition-all group-hover:stroke-[#D5F285]`}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 7L7 1L13 7" />
                </svg>
              </Listbox.Button>

              <Transition
                className="absolute right-0"
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Listbox.Options className="absolute right-0 top-6 w-[144px] divide-y divide-gray-2 rounded-lg border border-[#424545] bg-black">
                  {languages.map((language) => (
                    <Listbox.Option key={language.name} value={language}>
                      {({ active }) => (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <button
                          type="button"
                          className={classNames(
                            `flex group gap-2 justify-between items-center w-full p-2 text-left text-white transition-colors text-[14px] hover:bg-transparent`,
                          )}
                          style={{
                            // backgroundColor: active ? '#424545' : 'transparent',
                            color: active ? '#D5F285' : '#fff',
                          }}
                        >
                          {language.name}

                          <img
                            src={`/promotional/b2b/landing/dropdown/${language.name}.svg`}
                            alt={language.name}
                            className="transition-all duration-75 group-hover:[filter:brightness(0)_saturate(100%)_invert(99%)_sepia(48%)_saturate(1130%)_hue-rotate(16deg)_brightness(93%)_contrast(105%)]"
                          />
                        </button>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </>
          )}
        </Listbox>
      </div>

      <div className="flex max-h-[338px] flex-auto overflow-y-auto [font-family:'IBM_Plex_Mono'!important] mobile:max-h-[286px] container:max-h-[194px]">
        <SyntaxHighlighter
          customStyle={{
            backgroundColor: 'transparent',
            fontSize: '13px',
            fontFamily: '"IBM Plex Mono"',
            width: '100%',
          }}
          showLineNumbers
          language="javascript"
          style={hopscotch}
        >
          {JSON.parse(JSON.stringify(selectedLanguage.snippet, null, 2))}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

const SolutionsCode = () => {
  return (
    <section className="flex flex-col items-center bg-black text-white">
      <div className="flex w-full max-w-[840px] flex-col items-center gap-10 px-4 py-12 mobile:max-w-[1280px] mobile:px-10 tablet:px-24 footer:px-10">
        <div className="flex max-w-[800px] flex-col gap-3 text-center">
          <h2 className="font-medium text-header-2">One API. All assets. All services.</h2>

          <p>
            Get access to all of our assets and liquidity services through our RESTful API. We also
            have a web app that you can embed or link to from within your own products.
          </p>
        </div>

        <div className="flex w-full flex-col gap-4 footer:flex-row">
          <SolutionsCodeEditor />

          <div className="flex flex-1 flex-col gap-4">
            <div className="flex flex-col items-center justify-between gap-4 rounded-xl border border-gray-2 px-4 py-8 mobile:flex-row mobile:px-6 container:flex-1">
              <h4 className="text-[16px] font-medium">Check out our API docs</h4>

              <a
                className="flex-none"
                href="https://docs.structure.fi/"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  onClick={() => trackUserEvent('View API docs callout clicked')}
                  className="border-white bg-transparent font-normal hover:border-[#8A8A8A] hover:bg-transparent"
                  variant="primary"
                  size="small"
                >
                  Visit API docs
                </Button>
              </a>
            </div>

            <div className="flex flex-col items-center justify-between gap-4 rounded-xl border border-gray-2 px-4 py-8 mobile:flex-row mobile:gap-8 mobile:px-6 container:flex-[2]">
              <h4 className="max-w-60 text-center text-[16px] font-medium mobile:max-w-81 mobile:text-left container:max-w-[270px]">
                Don&apos;t have the time or resources for an API integration? We&apos;ve got you
                covered.
              </h4>

              <a className="flex-none" href="mailto:sales@structure.fi">
                <Button
                  onClick={() => trackUserEvent('Connect with us CTA clicked')}
                  className="border-white bg-transparent font-normal hover:border-[#8A8A8A] hover:bg-transparent"
                  variant="primary"
                  size="small"
                >
                  Connect with us
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SolutionsCode;
