import { Button } from 'ui';

import trackUserEvent from '@/util/eventTracking';

const SolutionsClosingCTA = () => {
  return (
    <section className="mx-auto mb-12 max-w-[1240px] px-4 mobile:px-12 tablet:mb-20 ">
      <div className="flex flex-col items-center gap-6 rounded-xl bg-[#F5F0EB] py-8 px-6 text-center font-medium text-header-3 mobile:p-12 tablet:min-h-[225px] tablet:flex-row tablet:justify-between">
        <h3 className="tablet:max-w-[616px] tablet:text-left">
          Get 500+ fully compliant tokenized assets for your platform.
        </h3>
        <a className="flex-none" href="mailto:sales@structure.fi">
          <Button
            onClick={() => trackUserEvent('Clicked Closing B2B CTA')}
            className="font-normal"
            variant="primary"
          >
            Talk with us today
          </Button>
        </a>
      </div>
    </section>
  );
};

export default SolutionsClosingCTA;
