/* eslint-disable @next/next/no-img-element */
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import SolutionsHero from '@/components/solutions/SolutionsHero';
import SolutionsCode from '@/components/solutions/SolutionsCode';
import SolutionsTrust from '@/components/solutions/SolutionsTrust';
import SolutionsFooter from '@/components/solutions/SolutionsFooter';
import SolutionsLiquidity from '@/components/solutions/SolutionsLiquidity';
import SolutionsInvestors from '@/components/solutions/SolutionsInvestors';
import SolutionsClosingCTA from '@/components/solutions/SolutionsClosingCTA';
import SolutionsCompliance from '@/components/solutions/SolutionsCompliance';

const SolutionsPage = () => {
  return (
    <>
      <div className="relative mx-auto hidden h-0 w-full max-w-desktop homepage:block">
        <img
          src="/promotional/b2b/landing/nio.svg"
          className="absolute left-12 top-36 coins:left-0"
          width={78}
          height={70}
          alt="Nio Token"
        />
        <img
          src="/promotional/b2b/landing/airbnb.svg"
          className="absolute right-12 top-24 coins:right-0"
          width={78}
          height={72}
          alt="Airbnb Token"
        />
      </div>

      <SolutionsHero />
      <SolutionsTrust />
      <SolutionsLiquidity />
      <SolutionsCompliance />
      <SolutionsCode />
      <SolutionsInvestors />
      <SolutionsClosingCTA />
      <SolutionsFooter />
    </>
  );
};

export default SolutionsPage;

/**
 * This is to handle translation server-side.
 */
export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale || 'en-US', [
        'errors',
        'common',
        'homepage',
        'blog',
        'footer',
      ])),
    },
  };
}
